<template>
  <FormLabel
    v-if="data && (value !== undefined || rule)"
    :class="!errors && 'mb-4'"
  >
    <VueDatePicker 
      v-if="rule"
      :id="customId || dataKey"
      :format="format"
      :model-value="value"
      class="custom-input"
      :class="errors?.length && 'is-error'"

      :min-date="getMin()"
      :max-date="getMax()"

      position="center" 
      locale="fr"
      cancel-text="Annuler"
      select-text="Valider"
      :action-row="{ showNow: true }"
      now-button-label="Aujourd'hui"
      :enable-time-picker="enableTimePicker"
      :disabled="disabled"
      :aria-label="this.dataKey"

      auto-apply

      time-picker-inline
      hide-input-icon
      @update:model-value="$emit('updateData', getDateFormat($event))"
      @blur="validation && validation.$touch()"
    >
      <template #trigger>
        <v-text-field
          :value="format(value)"
          clearable
          :disabled="disabled"
          density="compact"
          class="custom-input"
          variant="outlined"
          @click:clear="$emit('updateData', null)"
        />
      </template>
    </VueDatePicker>
    <div v-else>
      <div v-if="value">
        {{ format(value) }}
      </div>
      <div v-else>
        <small class="font-italic text-disabled">-</small>
      </div>
    </div>
    <!-- ERROR HANDLE -->
    <div
      v-if="errors"
      class="v-input__details text-error pl-4"
    >
      <div
        class="v-messages"
        role="alert"
        style="opacity : 1"
      >
        <div class="v-messages__message">
          {{ errors.join(',') }}
        </div>
      </div><!---->
    </div>
  </FormLabel>
</template>

<script lang="ts">
import formFieldsInit from '../../utils/formFieldsInit.vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment'
export default {
    name : "DatetimeField",
    components : {VueDatePicker},
    extends : formFieldsInit,
    props : {
        enableTimePicker : {type : Boolean, required : false }
    },
emits: ['updateData'],
    data () {
        return {
            moment,
        }
    },
    computed : {
        errors () {
            return this.getErrors()
        },
    },
    mounted () {
        moment.locale('fr')
    },
    methods : {
        getDateFormat (date) {
            return date ? this.enableTimePicker ? moment(date).utc(1).format('YYYY-MM-DD HH:mm') : moment(date).utc(1).format('YYYY-MM-DD') : null
        },
        format (date) {
            return this.enableTimePicker ? this.$formatDateTime(date) : this.$formatDate(date)
        }
    }
}
</script>
